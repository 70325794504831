<template>
  <div class="hold-transition">
    <div
      class="modal fade"
      id="modal-form-finalizacion-tepTurno"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Finalizar Turno</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- <div class="form-group col-md-12">
              <label>Buscar Ubicación:</label>
              <gmap-autocomplete
                class="form-control form-control-sm"
                @place_changed="setPlace"
                :options="{
                  fields: [
                    'geometry',
                    'formatted_address',
                    'address_components',
                  ],
                }"
              >
                >
              </gmap-autocomplete>
            </div> -->
            <div class="row">
              <div class="form-group col-md-5">
                <label for="fecha_final">Fecha</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  id="fecha_final"
                  placeholder="Fecha Inicial"
                  v-model="form_fechas.fecha_final"
                  :class="
                    $v.form_fechas.fecha_final.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
              <div class="form-group col-md-5">
                <label for="hora_final">Hora</label>
                <input
                  type="text"
                  id="hora_final"
                  class="form-control form-control-sm"
                  v-model="form_fechas.hora_final"
                  :class="
                    $v.form_fechas.hora_final.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  v-mask="{
                    mask: '99:99',
                    hourFormat: '24',
                  }"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-if="$store.getters.can('tep.turnos.finalizar')"
              v-show="!$v.form_fechas.$invalid"
              @click="save()"
            >
              Finalizar
            </button>
          </div>
        </div>
        <div class="modal-footer justify-content-between"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, helpers } from "vuelidate/lib/validators";
import moment from "moment";
const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
export default {
  name: "TepTurnoFinalizar",
  components: {},
  data() {
    return {
      form_turno: {
        vehiculo: {
          ultimo_registro: {
            gps: {},
          },
        },
        lat: null,
        long: null,
        fecha_fin: null,
        fecha_ini: null,
      },
      form_fechas: {
        fecha_final: null,
        hora_final: null,
      },
      fechaAct: null,
      fechaCorte: null,
    };
  },
  validations: {
    form_fechas: {
      fecha_final: {
        required,
      },
      hora_final: {
        required,
        timeFormat,
      },
    },
  },
  methods: {
    async llenar_modal(id, vehiculo, fecha_ini) {
      this.form_turno = {
        id: id,
        vehiculo: vehiculo,
        fecha_ini: fecha_ini,
      };
    },

    save() {
      if (
        this.form_fechas.fecha_final !== null &&
        this.form_fechas.hora_final !== null
      ) {
        this.fechaAct = new Date(
          this.form_fechas.fecha_final + " " + this.form_fechas.hora_final
        );
        this.fechaCorte = moment(this.fechaAct)
          .add(-2, "hours")
          .format("YYYY-MM-DD HH:mm:ss");

        this.fechaAct2 = moment(this.fechaAct).format("YYYY-MM-DD HH:mm:ss");
        // 7pm - 6pm // 5pm
        if (
          this.form_turno.vehiculo.ultimo_registro.fecha_hora_ultimo_reporte >=
          this.fechaCorte
        ) {
          this.form_turno.lat =
            this.form_turno.vehiculo.ultimo_registro.gps.dblLatitud;
          this.form_turno.long =
            this.form_turno.vehiculo.ultimo_registro.gps.dblLongitud;
        } else {
          this.form_turno.lat = null;
          this.form_turno.long = null;
        }
        this.form_turno.fecha_fin = this.form_fechas.fecha_final + " " + this.form_fechas.hora_final;

        if (this.form_turno.fecha_fin > this.form_turno.fecha_ini) {
          axios({
            method: "PUT",
            url: "/api/tep/turnoFinalizar",
            data: this.form_turno,
          })
            .then((response) => {
              this.form_estado = {};
              this.$refs.closeModal.click();
              this.$swal({
                icon: "success",
                title: response.data.msg,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch((e) => {
              this.cargando = false;
              this.$swal({
                icon: "error",
                title: "Ocurrio un error, vuelva a intentarlo..." + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        } else {
          this.$swal({
            text:
            "La fecha y hora de finalización no puede ser menor o la igual que la inicial: " +
            this.form_turno.fecha_ini,
            icon: "warning",
            confirmButtonText: "¡Aceptar!",
          });
        }
      }

      this.$parent.getIndex();
    },
  },
};
</script>

<style>
.pac-container {
  z-index: 10000;
}
</style>
