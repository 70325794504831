var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[_c('div',{staticClass:"modal fade",staticStyle:{"background-color":"#00000082"},attrs:{"id":"modal-form-finalizacion-tepTurno"}},[_c('div',{staticClass:"modal-dialog modal-xl"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v("Finalizar Turno")]),_c('button',{ref:"closeModal",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-5"},[_c('label',{attrs:{"for":"fecha_final"}},[_vm._v("Fecha")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form_fechas.fecha_final),expression:"form_fechas.fecha_final"}],staticClass:"form-control form-control-sm",class:_vm.$v.form_fechas.fecha_final.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"type":"date","id":"fecha_final","placeholder":"Fecha Inicial"},domProps:{"value":(_vm.form_fechas.fecha_final)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form_fechas, "fecha_final", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-5"},[_c('label',{attrs:{"for":"hora_final"}},[_vm._v("Hora")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form_fechas.hora_final),expression:"form_fechas.hora_final"},{name:"mask",rawName:"v-mask",value:({
                  mask: '99:99',
                  hourFormat: '24',
                }),expression:"{\n                  mask: '99:99',\n                  hourFormat: '24',\n                }"}],staticClass:"form-control form-control-sm",class:_vm.$v.form_fechas.hora_final.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"type":"text","id":"hora_final"},domProps:{"value":(_vm.form_fechas.hora_final)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form_fechas, "hora_final", $event.target.value)}}})])])]),_c('div',{staticClass:"modal-footer justify-content-between"},[(_vm.$store.getters.can('tep.turnos.finalizar'))?_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.form_fechas.$invalid),expression:"!$v.form_fechas.$invalid"}],staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Finalizar ")]):_vm._e()])]),_c('div',{staticClass:"modal-footer justify-content-between"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }